import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import React, { useCallback, useEffect, useState } from 'react'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { Colors } from '../../constants/theme'
import BaseIconButton from '../Buttons/BaseIconButton'

interface CarouselProps<T> {
  items: T[]
  onItemClick?: (item: T) => void
  children: (props: {
    item: T
    index: number
    onItemClick?: (item: T) => void
    selected?: boolean
  }) => React.ReactElement
  carouselOptions?: EmblaOptionsType
  carouselOptionsDesktop?: EmblaOptionsType
  carouselOptionsMobile?: EmblaOptionsType
  showButtons?: boolean
  showDots?: boolean
  showButtonsMb?: boolean
  showDotsMb?: boolean
  showButtonsMd?: boolean
  showDotsMd?: boolean
  alignDots?: 'justify-start' | 'justify-center' | 'justify-end'
  marginButtons?: 2 | 4
  fillAvailable?: boolean
}

function Carousel<T>({
  items = [],
  onItemClick,
  children,
  carouselOptions,
  carouselOptionsDesktop,
  carouselOptionsMobile,
  showDots,
  showButtons,
  showDotsMb,
  showButtonsMb,
  showDotsMd,
  alignDots = 'justify-center',
  showButtonsMd,
  marginButtons,
  fillAvailable
}: CarouselProps<T>) {
  const { isDownMd, isUpMd } = useBreakpoints()

  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const [itemViewportRef, embla] = useEmblaCarousel({
    ...carouselOptions,
    ...(isDownMd && carouselOptionsMobile),
    ...(isUpMd && carouselOptionsDesktop)
  })

  useEffect(() => {
    if (!embla) return

    const onSelect = () => setSelectedIndex(embla.selectedScrollSnap())
    const onInit = () => setScrollSnaps(embla.scrollSnapList())

    onInit()
    onSelect()
    embla.on('reInit', onInit)
    embla.on('reInit', onSelect)
    embla.on('select', onSelect)
  }, [embla])

  useEffect(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    embla.scrollTo(embla.selectedScrollSnap())
  }, [embla])

  const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla?.scrollNext(), [embla])
  const scrollTo = useCallback(
    (index: number) => embla?.scrollTo(index),
    [embla]
  )

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  const handleItemClick = (item: T) => {
    if (onItemClick) onItemClick(item)
  }

  const buttons =
    showButtons || (showButtonsMb && isDownMd) || (showButtonsMd && isUpMd)
  const dots = showDots || (showDotsMb && isDownMd) || (showDotsMd && isUpMd)

  return (
    <div className={`relative block ${fillAvailable ? 'w-full' : ''}`}>
      <div className="relative mx-auto block">
        <div
          className="block w-full overflow-hidden rounded-xl"
          ref={itemViewportRef}
        >
          <div className="flex cursor-default select-none tap-highlight-transparent">
            {items.map((item, index) => (
              <div
                className={`relative h-min px-[10px] py-0 ${index !== selectedIndex ? 'h-0' : ''}`}
                key={index}
                onClick={() => handleItemClick(item)}
              >
                {children({
                  item,
                  index,
                  onItemClick,
                  selected: index === selectedIndex
                })}
              </div>
            ))}
          </div>
        </div>
      </div>

      {(buttons || dots) && (
        <div
          className={`pointer-events-none relative my-[10px] ${marginButtons ? (marginButtons === 4 ? `mt-[40px]` : marginButtons === 2 ? 'mt-[20px]' : '') : ''} box-border flex w-full -translate-y-1/2 items-center ${alignDots} gap-5 px-[10px]`}
        >
          {buttons && (
            <BaseIconButton
              id="previous-btn-carousel"
              aria-label="previous-btn-left"
              variant="contained"
              customColor="transparent"
              onClick={scrollPrev}
              sx={{
                visibility: prevBtnEnabled ? 'visible' : 'hidden',
                pointerEvents: 'all'
              }}
            >
              <ArrowBackIosNewIcon
                fontSize="medium"
                style={{ color: Colors.grey500 }}
              />
            </BaseIconButton>
          )}

          {dots && (
            <div className="flex justify-center">
              {scrollSnaps.map((_, index) => (
                <div
                  className={`size-2 rounded-full ${
                    index === selectedIndex ? 'bg-blue-500' : 'bg-grey-500'
                  } mx-1 hover:cursor-pointer`}
                  key={index}
                  onClick={() => scrollTo(index)}
                />
              ))}
            </div>
          )}

          {buttons && (
            <BaseIconButton
              id="next-btn-carousel"
              aria-label="next-btn-right"
              variant="contained"
              customColor="transparent"
              onClick={scrollNext}
              sx={{
                visibility: nextBtnEnabled ? 'visible' : 'hidden',
                pointerEvents: 'all'
              }}
            >
              <ArrowForwardIosIcon
                fontSize="medium"
                style={{ color: Colors.grey500 }}
              />
            </BaseIconButton>
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(Carousel) as typeof Carousel
