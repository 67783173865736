import { useTranslation } from 'next-i18next'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import React from 'react'
import Carousel from 'src/components/Carousel/Carousel'
import { SearchPage } from 'src/constants/searchPages'
import BaseButton from '../../components/Buttons/BaseButton'
import { RedirectUrls } from '../../types/types'
import { PERSONALIZED_PAGES } from '../PersonalizedLanding/constants'

type ICard = {
  title: string
  subtitle: string
  picture: StaticImageData | string
  shadow?: boolean
  alt: string
  search: string
}

type IProps = {
  item: ICard
  imgHeight: string
}

const ServicesSection = () => {
  const { t } = useTranslation('landing')
  const CARDS = t('INDEX_SERVICES.CARDS', { returnObjects: true })

  const AlojamientoCard = ({ item, imgHeight }: IProps) => {
    const { title, subtitle, picture, alt, search } = item
    const backup =
      Object.values(SearchPage).find((page) => page === search) ??
      SearchPage.Bodega1
    const page = PERSONALIZED_PAGES.find((page) => page === search)
      ? `/renta/${search}`
      : `/${backup}`
    return (
      <Link
        id={`${title.trim()}${subtitle.trim()}`}
        key={`${title.trim()}${subtitle.trim()}`}
        href={page}
        passHref
        className="flex w-full min-w-64 flex-col overflow-hidden rounded-[30px] bg-grey-300 md:min-w-full sm-md:min-w-[170px]"
      >
        <div
          className={`center relative flex ${imgHeight} w-full items-center justify-center overflow-hidden`}
        >
          <Image src={picture} alt={alt} fill style={{ objectFit: 'cover' }} />
        </div>
        <div className="relative flex w-full flex-col px-[30px] pb-[6px] pt-2">
          <p className="text-[15px] text-black-400">{title}</p>
          <p className="relative -top-1 text-[15px] font-semibold text-black-400">
            {subtitle}
          </p>
        </div>
      </Link>
    )
  }

  return (
    <section className="relative flex w-full flex-col items-center justify-center py-[90px] md:px-5 lg:px-7 xl:px-12 sm-md:px-5">
      <h3 className="w-full px-5 text-center text-[32px] font-semibold leading-9 text-black-400">
        {t('INDEX_SERVICES.HEADING')}
      </h3>
      <p className="mt-2 px-5 text-center text-[15px] text-black-400">
        {t('INDEX_SERVICES.SUBHEADING_FR_1')}{' '}
        <span className="font-semibold text-blue-500">
          {t('INDEX_SERVICES.SUBHEADING_FR_2')}
        </span>
      </p>
      <div className="mt-11 hidden w-full max-w-[1832px] grid-cols-12 gap-5 md:grid sm-md:grid">
        {CARDS.map((item, index) => (
          <div
            key={index}
            className={`${index < 4 ? 'col-span-6' : 'col-span-4'} ${index < 3 ? 'md:col-span-4' : 'md:col-span-3'}`}
          >
            <AlojamientoCard
              item={item}
              imgHeight={`${index < 4 ? 'h-[180px] max-h-[180px]' : 'h-[166px] max-h-[166px]'} ${index < 3 ? 'md:h-[180px] md:max-h-[180px]' : 'md:h-[166px] md:max-h-[166px]'}`}
            />
          </div>
        ))}
      </div>
      <div className="relative mt-11 w-full overflow-hidden md:hidden sm-md:hidden">
        <Carousel
          carouselOptions={{ loop: true }}
          carouselOptionsDesktop={{ align: 'start' }}
          items={CARDS}
        >
          {({ item }) => (
            <AlojamientoCard item={item} imgHeight={'h-[111px]'} />
          )}
        </Carousel>
      </div>
      <div className="flex w-full flex-col flex-wrap items-center justify-center gap-[23px] px-5 pt-11 sm:flex-row">
        <BaseButton
          fontSize={14}
          size="medium"
          width={245}
          color="primary"
          variant="contained"
          rounded
          href={RedirectUrls.Search}
        >
          {t('INDEX_SERVICES.BUTTON_SEARCH')}
        </BaseButton>
        <BaseButton
          fontSize={14}
          size="medium"
          width={245}
          color="primary"
          variant="contained"
          rounded
          href={RedirectUrls.Anfitrion}
        >
          {t('INDEX_SERVICES.BUTTON_IAM_HOST')}
        </BaseButton>
      </div>
    </section>
  )
}

export default React.memo(ServicesSection)
